<template>
    <div class="market">
        <div class="head flex">
            <div>营销政策</div>
            <a-button type="primary" @click="showDrawer">添加营销政策</a-button>
        </div>
        <div class="flex_inline">
             <div class="list" v-if="List" v-for="(item,index) in List" @click="preview(index)" @mouseover="_hoverActive(1,index)" :class="active1==index?'list-bth':''">
                 <div class="title">{{item.Title}}</div>
                 <div class="time">{{item.CreateTime}}</div>
                 <div class="flex action">
                     <span :class="item.Status==2?'ing':''">{{item.Status==1?'政策暂未开始':item.Status==2?'政策进行中':'政策已结束'}}</span>
                     <div>
                         <i class="iconfont icon-bianji" v-if="item.power ==1" @click.capture.stop="SaveInfo(index,item.Id)"></i>
                         <a-popconfirm v-if="item.power ==1"  placement="bottom" cancelText="取消" okText="确定" @confirm.stop.prevent="Del(index,item.Id)" @cancel="maxClassify=-1">
                             <template slot="title">
                                 <p>确定删除么？</p>
                             </template>
                             <i class="iconfont icon-shanchu- " >&nbsp</i>
                         </a-popconfirm>
                     </div>
                 </div>
             </div>
           
        </div>

        <!--预览-->
        <a-drawer
                title="活动详情"
                width="800"
                :closable="false"
                @close="onClose"
                :visible="visibleDetail"
        >
            <div class="drawer-body">
                <div class="preview">
                    <span class="preview-title">政策名称:</span>
                    <span class="preview-detail">{{Title}}</span>
                </div>
                <div class="preview">
                    <span class="preview-title">有效时间:</span>
                    <span class="preview-detail">{{StartTime}}-{{EndTime}}</span>
                </div>
                <div class="preview">
                    <span class="preview-title">政策说明:</span>
                    <span class="preview-detail" v-html="Content"></span>
                </div>
                <h1 class="usefull">常用问题解答</h1>
                <a-collapse >
                    <a-collapse-panel v-for="(item,index) in FAQ" :header="(index+1)+'、'+item.problem" :key="index" :disabled="false" >
                        <p v-html="item.answer">{{item.answer}}}</p>
                    </a-collapse-panel>
                    
                </a-collapse>
            </div>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
            >
                <a-button @click="onClose" type="primary">
                    关闭
                </a-button>
            </div>
        </a-drawer>
        <!--创建、修改-->
        <a-drawer
                :title="Id?'修改营销政策':'添加营销政策'"
                width="800"
                :closable="false"
                @close="onClose"
                :visible="visible"
        >
            <div class="drawer-body">
                <div class="row">
                    <div class="flex-row">
                        <span class="label-text">政策名称</span>
                        <a-input class="item" v-model="Title" placeholder="请输入活动主题" />
                    </div>
                </div>
                <div class="row">
                    <div class="flex-row">
                        <span class="label-text">有效时间</span>
                        <a-range-picker v-if="StartTime" :value="[moment(StartTime, dateFormat), moment(EndTime, dateFormat)]"  show-time style="flex: 1;" format="YYYY-MM-DD HH:mm"  :placeholder="['开始时间2','结束时间2']"   allowClear @change="ChangeTime"  />
                        <a-range-picker v-else show-time style="flex: 1" format="YYYY-MM-DD HH:mm"  :placeholder="['开始时间','结束时间']"   allowClear @change="ChangeTime"  />
                    </div>
                </div>
                <div class="row">
                    <div class="flex-row">
                        <span class="label-text">政策说明</span>
                        <div id="websiteEditorElem" class="websiteEditorElem" style="width: 600px"></div>
                    </div>
                </div>
                <h1 class="seeting">常见问题设置</h1>
                <div class="question-list" v-for="(item,index) in FAQ">
                    <div class="row">
                        <div class="flex-row">
                            <span class="label-text">问题{{index+1}}</span>
                            <a-input class="item"  placeholder="" v-model="item.problem" />

                            <div v-if="index>=1" class="circle-bth">
                                <a-icon  @click="Remove(index)" type="minus-circle" />
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="flex-row">
                            <span class="label-text">答案</span>
                            <div class="item" :class="'problem'+index" >
                            </div>
                            <!--<a-textarea class="item" v-model="item.answer" :rows="4" />-->
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="flex-row">
                        <span class="label-text"></span>
                        <a-button class="item" @click="addQuestion">+添加问题</a-button>
                    </div>
                </div>
            </div>



            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'left',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose">
                    取消
                </a-button>
                <a-button @click="submit" type="primary">
                    {{Id?'修改':'添加'}}
                </a-button>
            </div>
        </a-drawer>

    </div>
</template>

<script>
    
    import moment from 'moment';

    import E from 'wangeditor';
    
    export default {
        name: "market",
        data(){
            return{
                dateFormat:'YYYY-MM-DD HH:mm:ss',
                visible:false,
                Content:'',
                editorOption: { /* quill options */ } ,
                phoneEditor:'',
                phoneEditorHtml: '',
                FAQ:[{'problem':'','answer':''}],
                Title:'',
                StartTime:'',
                EndTime:'',
                Content:'',
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                Id:'',
                List:'',
                visibleDetail:false,
                previewStatus:true,
                active1:-1,
                answer:[],

            }
        },
        created(){
             this.loadData();
        },
        
        methods:{
            moment,
            loadData(){
                let self = this;
                this.$axios.get(1071,{uid:self.userinfo.uid},res=>{
                    console.log(res)
                    if(res.data.code ==1){
                         self.List = res.data.message;
                    }
                })
            },
            Del(i,id){
                let self = this;
                this.$axios.get(1070,{
                    uid:self.userinfo.uid,
                    Id:id,
                },res=>{
                    if(res.data.code ==1){
                        this.$message.success(res.data.message);
                        if(self.List.length ==1){
                            self.List = '';
                        }else{
                            self.List.splice(i,1);
                        }

                    }else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            SaveInfo(i,id){
                this.Title = this.List[i]['Title'];
                this.Content = this.List[i]['Content'];
                this.StartTime = this.List[i]['StartTime'];
                this.EndTime = this.List[i]['EndTime'];
                this.FAQ = JSON.parse(this.List[i]['FAQ']);
                this.Id = this.List[i]['Id'];
                this.phoneEditorHtml = this.List[i]['Content'];
                this.showDrawer();
            },
            ChangeTime(time, timeString){
                this.StartTime = timeString[0];
                this.EndTime   = timeString[1];
            },
            submit(){
                let self = this;
                this.$axios.post(1068,{
                    Content:self.Content,
                    Title:self.Title,
                    StartTime:self.StartTime,
                    EndTime:self.EndTime,
                    FAQ:JSON.stringify(self.FAQ),
                    uid:self.userinfo.uid,
                    name:self.userinfo.name,
                    Id:self.Id
                },res=>{
                    if(res.data.code ==1){
                        this.$message.success(res.data.message);
                        self.loadData();
                        self.onClose();
                    }else{
                        this.$message.error(res.data.message);
                    }
                })

            },
            preview(i){
                console.log(i)
                this.Title = this.List[i]['Title'];
                this.Content = this.List[i]['Content'];
                this.StartTime = this.List[i]['StartTime'];
                this.EndTime = this.List[i]['EndTime'];
                this.FAQ = JSON.parse(this.List[i]['FAQ']);
                this.Id = this.List[i]['Id'];
                this.visibleDetail = true;
                this.previewStatus = false;
            } ,
            _hoverActive(index,i){
                if(index ==1){
                    this.active1 = i;
                }
            },
            showDrawer() {
                let self = this;
                setTimeout(function () {
                    self.phoneEditor = new E('.websiteEditorElem');
                    self.phoneEditor.customConfig.customUploadImg = (files, insert) => {
                        self.__uploadFile__(files).then(res=>{
                            if(res){
                                if(res){
                                     for (var i=0;i<res.length;i++){
                                         insert(res[i].FileUrl);
                                     }
                                }
                            }
                        })
                    };
                    self.phoneEditor.customConfig.menus = [
                        'head',
                        'bold',
                        'italic',
                        'underline',
                        'foreColor',  // 文字颜色
                        'backColor',  // 背景颜色
                        'list',
                        'link',  // 插入链接
                        'justify',  // 对齐方式
                        'quote',  // 引用
                        'emoticon',  // 表情
                        'image',  // 插入图片
                        'table',  // 表格
                    ];
                    self.phoneEditor.customConfig.onchange = function (html) {

                        self.Content = html;
                    };
                    // 创建一个富文本编辑器
                    self.phoneEditor.create();
                    self.phoneEditor.txt.html(self.phoneEditorHtml);
                    if(self.Id){

                        for (var i=0;i<self.FAQ.length;i++){
                            if(i==0){
                                let problemEditor0 = new   E('.problem'+i);
                                problemEditor0.customConfig.menus =  false;
                                problemEditor0.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[0].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor0.create();
                                problemEditor0.txt.html(self.FAQ[i].answer);
                            }else if(i==1){
                                let problemEditor1 = new   E('.problem'+i);
                                problemEditor1.customConfig.menus =  false;
                                problemEditor1.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[1].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor1.create();
                                problemEditor1.txt.html(self.FAQ[i].answer);
                            }else if(i==2){
                                let problemEditor2 = new   E('.problem'+i);
                                problemEditor2.customConfig.menus =  false;
                                problemEditor2.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[2].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor2.create();
                                problemEditor2.txt.html(self.FAQ[i].answer);
                            }else if(i==2){
                                let problemEditor2 = new   E('.problem'+i);
                                problemEditor2.customConfig.menus =  false;
                                problemEditor2.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[2].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor2.create();
                                problemEditor2.txt.html(self.FAQ[i].answer);
                            }else if(i==3){
                                let problemEditor3 = new   E('.problem'+i);
                                problemEditor3.customConfig.menus =  false;
                                problemEditor3.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[3].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor3.create();
                                problemEditor3.txt.html(self.FAQ[i].answer);
                            }else if(i==4){
                                let problemEditor4 = new   E('.problem'+i);
                                problemEditor4.customConfig.menus =  false;
                                problemEditor4.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[4].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor4.create();
                                problemEditor4.txt.html(self.FAQ[i].answer);
                            }else if(i==5){
                                let problemEditor5 = new   E('.problem'+i);
                                problemEditor5.customConfig.menus =  false;
                                problemEditor5.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[5].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor5.create();
                                problemEditor5.txt.html(self.FAQ[i].answer);
                            }else if(i==6){
                                let problemEditor6 = new   E('.problem'+i);
                                problemEditor6.customConfig.menus =  false;
                                problemEditor6.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[6].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor6.create();
                                problemEditor6.txt.html(self.FAQ[i].answer);
                            }else if(i==7){
                                let problemEditor7 = new   E('.problem'+i);
                                problemEditor7.customConfig.menus =  false;
                                problemEditor7.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[7].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor7.create();
                                problemEditor7.txt.html(self.FAQ[i].answer);
                            }else if(i==8){
                                let problemEditor8 = new   E('.problem'+i);
                                problemEditor8.customConfig.menus =  false;
                                problemEditor8.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[8].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor8.create();
                                problemEditor8.txt.html(self.FAQ[i].answer);
                            }else if(i==9){
                                let problemEditor9 = new   E('.problem'+i);
                                problemEditor9.customConfig.menus =  false;
                                problemEditor9.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[9].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor9.create();
                                problemEditor9.txt.html(self.FAQ[i].answer);
                            }else if(i==10){
                                let problemEditor10 = new   E('.problem'+i);
                                problemEditor10.customConfig.menus =  false;
                                problemEditor10.customConfig.onchange = function (html) {
                                    console.log(i);
                                    self.FAQ[10].answer = html;

                                };
                                // 创建一个富文本编辑器
                                problemEditor10.create();
                                problemEditor10.txt.html(self.FAQ[i].answer);
                            }


                        }
                    }else{
                        for (var i=0;i<self.FAQ.length;i++){
                            let problemEditor = new   E('.problem'+i);
                            problemEditor.customConfig.menus =  false;
                            problemEditor.customConfig.onchange = function (html) {
                                self.FAQ[0].answer = html;

                            };
                            // 创建一个富文本编辑器
                            problemEditor.create();
                            problemEditor.txt.html('');

                        }
                    }

                    

                });

                this.visible = true;
            },

            onClose(){
                this.visible = false;
                this.visibleDetail = false;
                if(this.previewStatus){
                    document.getElementsByClassName('websiteEditorElem').innerHTML = '';
                    for (var i=0;i<this.FAQ.length;i++){
                        document.getElementsByClassName('problem'+i).innerHTML = '';
                    }
                }
                this.previewStatus = true;
                this.FAQ=[{'problem':'','answer':''}];
                this.Title='';
                this.StartTime='';
                this.EndTime='';
                this.Content='';
                this.phoneEditorHtml = '';
                this.Id = '';
            },
            addQuestion(){
                let arr = {'problem':'','answer':''};
                this.FAQ.push(arr);
                let self = this;
                setTimeout(function(){
                    let startNum = self.FAQ.length-1;
                    for (var i=startNum;i<self.FAQ.length;i++){
                        let problemEditor = new  E('.problem'+i);
                        problemEditor.customConfig.menus =false ;
                        problemEditor.customConfig.onchange = function (html) {
                            self.FAQ[i-1].answer = html;
                        };
                        // 创建一个富文本编辑器
                        problemEditor.create();
                        problemEditor.txt.html('');

                    }
                })

            },
            Remove(i){
                
                this.FAQ.splice(i,1);
            }
        }
    }
</script>

<style scoped lang="less">
    *{
        font-family:Microsoft YaHei UI;
    }
    .market{
        padding: 20px 30px;
        background: #ffffff;
        min-height: 800px;
    }
    .drawer-body{
        padding-bottom: 60px;
    }
    .head{
        height: 60px;
        line-height: 60px;
        border-bottom: 1px solid #F3F3F9;
        margin-bottom: 40px;
        div{
            color: #2D3E5D;
            font-size: 20px;
        }
    }
    .flex_inline{
        display: inline-flex;
        flex-wrap:wrap;
        align-content:flex-start; //解决问题的样式
        margin: auto;
        
    }
    .list-bth{
        border: 1px solid rgba(40,148,255,1);
    }
    .list{
        width: 250px;
        padding: 20px 16px 30px 16px;
        background:rgba(255,255,255,1);
        box-shadow:0px 3px 4px rgba(14,25,36,0.09);
        border-radius:4px;
        margin-right: 30px;
        margin-bottom: 30px;
        .title{
            color: #666666;
            font-size: 16px;
            height: 48px;
            overflow-y: hidden;
        }
        .time{
            color: #999999;
            font-size: 12px;
            margin: 10px 0px;
        }
        .action{
            span{
                font-size:12px;
                color:rgba(153,153,153,1);
                position: relative;
                padding-left: 15px;
            }
            span::after{
                content: '';
                width:8px;
                height:8px;
                background:rgba(186,188,188,1);
                border-radius:50%;
                position: absolute;
                top: 50%;
                left:0;
                transform: translateY(-50%);
            }
            .ing{
                color:rgba(40,148,255,1);
            }
            .ing::after{
                background: #2894FF;
            }
            .icon-bianji{
                margin-right: 8px;
            }
        }
    }
    .row{
        display: block;
        margin-bottom: 20px;
        .flex-row{
            display: inline-flex;
            .item{
                width: 350px;
            }
            .label-text{
                display: block;
                width: 88px;
                height: 32px;
                line-height: 32px;
            }
            .circle-bth{
                height: 32px;
                line-height: 38px;
                .anticon-minus-circle{
                    font-size: 20px;
                    margin-left: 10px;
                    color: #999;
                    cursor: pointer;
                }
            }


        }
    }

    .seeting{
        color: #666666;
        font-size: 16px;
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .preview{
        margin-bottom: 20px;
        display:flex;
        .preview-title{
            display: block;
            width: 88px;
            color: #ABB0BF;
            font-size: 16px;
        }
        .preview-detail{
            color: #666666;
            display: block;
            font-size: 16px;
            max-width: 600px;
            table{
                border-top: 1px solid #ccc;
                border-left: 1px solid #ccc;
            }

        }
    }
    .usefull{
        color: #2D3E5D;
        font-size: 16px;
        margin-bottom: 23px;
        margin-top: 80px;
    }

</style>
<style>
    /* table 样式 */
    table {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }
    table  tr,
    table  td,
    table  th {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 3px 5px;
    }
    table tbody th {
        border-bottom: 2px solid #ccc;
        text-align: center;
    }

    /* blockquote 样式 */
    blockquote {
        display: block;
        border-left: 8px solid #d0e5f2;
        padding: 5px 10px;
        margin: 10px 0;
        line-height: 1.4;
        font-size: 100%;
        background-color: #f1f1f1;
    }

    /* code 样式 */
    code {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        background-color: #f1f1f1;
        border-radius: 3px;
        padding: 3px 5px;
        margin: 0 3px;
    }
    pre code {
        display: block;
    }
    p{
        display: block;
    }
    /* ul ol 样式 */
    /*ul, ol {*/
        /*margin: 10px 0 10px 20px;*/
    /*}*/
</style>